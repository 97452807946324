.error-wrapper {
  height: 100vh;
  position: relative;
  width: 100vw;
}

.error-paragraph {
  color: #0a1833;
  font-size: 34px;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
}

.error-code {
  color: #d60d64ff;
}
